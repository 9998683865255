<template>
	<div class="flex flex-col content-start h-full">
		<Header :classes="'items-end'">
			<div>
				<h1>Brukerprofil</h1>
			</div>
			
			<Tabs 
				class="flex-none justify-center md:justify-start"
				:tabs="[
					{
						name: 'Detaljer',
						alias: 'details',
					},
					{
						name: 'Innstillinger',
						alias: 'settings',
					},
                    {
                        name: 'Transaksjoner',
                        alias: 'transactions',
                    },
				]" 
				:active="activeSection" 
				@click="activeSection = $event.alias; $event.alias != 'transactions' ? createCopy() : null;"
			/>
		</Header>
		
		<div 
			v-if="loading == 'fetching'"
			class="h-full flex items-center justify-center">
			
			<div class="spinner"></div>
		</div>
        <section 
            v-else-if="userEdit && activeSection == 'details'"
            class="flex-grow p-6 md:p-12 overflow-auto">
			
			<div class="max-w-xl ">
					
				<form v-if="!verifyEmail && !showNeedToSignOut">
					<!-- <pre>{{ userEdit }}</pre> -->
					<label for="picture">Profilbilde</label>
					<div 
						v-if="userEdit.picture"
						class="w-full p-8 object-contain bg-beige relative">
						
						<img 
							:src="fileKeyToUrl( userEdit.picture )"
							alt="Logo"
							class="object-cover h-64 w-64 mx-auto rounded-full"
							style="max-width: 20rem;"
						/>
						
						<div class="absolute top-0 right-0 m-2">
							<i 
								class="bg-white p-2 fas fa-trash ml-2"
								@click="userEdit.picture = ''"
							/>
						</div>
					</div>
					<InputUpload
						v-else
						label="Last opp profilbilde"
						name="image"
						:multiple="false"
						@input="onImageAdded($event)"
					/>
					
					<InputSingleline 
						v-model="userEdit.given_name"
						type="text"
						label="Fornavn"
						:required="true"
						class="flex-1"
					/>
					
					<InputSingleline 
						v-model="userEdit.family_name"
						type="text"
						label="Etternavn"
						:required="true"
						class="flex-1"
					/>
					
					<InputSingleline 
						v-model="userEdit.phone_number"
						type="text"
						label="Telefonnummer"
						placeholder="+47xxxxxxxx"
						:required="false"
						class="flex-1"
					/>
					
					<InputSingleline 
						v-model="userEdit.email"
						type="email"
						label="E-postadresse"
						placeholder="john@example.com"
						:required="true"
						class="flex-1"
					/>
					
					<a @click="verifyEmail = true;" class="link">Har du fått en epost om å bekrefte din epostadresse?</a>
					
					<div class="mt-6">
						<button 
							type="submit"
							@click.prevent="onUpdateUserAttributes()" 
							:disabled="loading == 'updating'"
							class="button submit"
							:class="{'loading' : loading == 'updating'}">
							
							Lagre
						</button>
					</div>
				</form>
				<form v-else-if="verifyEmail">
					<InputSingleline 
						v-model="code"
						type="text"
						label="Kode sendt til din e-postadresse"
						:required="true"
					/>
					
					<div class="flex space-x-4">
						<button 
							@click="verifyEmail = false" 
							:disabled="loading"
							class="button">
							
							Avbryt
						</button>
						
						<button 
							type="submit"
							@click.prevent="onVerifyCurrentUserAttributeSubmit()" 
							:disabled="loading == 'updating'"
							class="button submit"
							:class="{'loading' : loading == 'updating'}">
							
							Verifiser nå
						</button>
					</div>
				</form>
				<div v-else-if="showNeedToSignOut">
					<p>Din e-postadresse ble verifisert, og du må logge ut, og inn igjen for å aktivere denne endringen.</p>
				</div>
			</div>
				
		</section>
		<section 
			v-else-if="userEdit && activeSection == 'settings'"
            class="flex-grow overflow-hidden ">
			<!-- <pre v-if="userEdit.profile">{{ userEdit.profile }}</pre> -->
			
			<form 
				v-if="userEdit.profile?.giphyId"
            	class="flex-grow overflow-auto p-6 md:p-12 max-w-xl">
			
				<label for="picture">Animasjon ved registrert salg / oppgave utført</label>
				<div class="w-full p-12 object-contain bg-beige relative">
					<!-- :src="userEdit.profile?.giphy" -->
					<img 
						:src="'https://media1.giphy.com/media/'+userEdit.profile?.giphyId+'/giphy.gif'"
					/>
					
					<i 
						class="fas fa-times absolute top-0 right-0 text-lg m-2 rounded-full bg-white p-1 w-8 h-8 flex items-center justify-center"
						@click="userEdit.profile.giphyId = null"
					/>
				</div>
				
				<div class="mt-6">
					<button 
						type="submit"
						@click.prevent="onUpdateUserAttributes()" 
						:disabled="loading == 'updating'"
						class="button submit"
						:class="{'loading' : loading == 'updating'}">
						
						Lagre
					</button>
				</div>
			</form>
			<div
				v-else
				class="flex-grow flex flex-col h-full ">
				
				<InputGiphy
					@onSelect="userEdit.profile.giphyId = $event.id"
				/>
			</div>
		</section>
		
		<section 
			v-else-if="userEdit && activeSection == 'transactions'"
            class="flex-grow overflow-hidden ">
			
            <TransactionsBrowse 
                :userPassed="userEdit" 
            />
		</section>
	</div>
</template>


<script>
import AuthMixins from '@/mixins/AuthMixins';
import {Auth} from '@aws-amplify/auth';

export default {
	components: {
		'InputGiphy': () => import('@/components/input/InputGiphy.vue'),
        'TransactionsBrowse': () => import('@/components/transactions/TransactionsBrowse.vue'),
    },
    
	mixins: [
		AuthMixins,
	],
	
	data() {
		return {
			loading: null,
			userEdit: null,
			verifyEmail: false,
			code: null,
			showNeedToSignOut: false,
			activeSection: 'details',
		}
	},
	
	methods: {
		async createCopy(){
			this.loading = 'fetching';
			
			await this.checkUser();
			
			this.userEdit = JSON.parse(
				JSON.stringify({
					profile: {
						giphyId: null,
					},
					picture: '',
					phone_number: '',
					...this.signedInUser.attributes,
					username: this.signedInUser.attributes.sub,
				})
			);
			
			if (this.signedInUser.attributes.profile) {
				this.userEdit.profile = JSON.parse( this.signedInUser.attributes.profile );
			}
			
			this.loading = null;
		},
		
		onImageAdded( file ){
			// alert('file');
			this.userEdit.picture = this.accountId+'/' + file.fileId +'.'+ file.extension;
			console.log(file);
		},
		
		async onUpdateUserAttributes(){
			try {
				this.loading = 'saving';
				this.userEdit.email = this.userEdit.email.toLowerCase().trim();
				
				const cognitoUser = await Auth.currentAuthenticatedUser({
					bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				});
				
				const response = await Auth.updateUserAttributes(cognitoUser, {
					given_name: this.userEdit.given_name || '',
					family_name: this.userEdit.family_name || '',
					phone_number: this.userEdit.phone_number || '',
					email: this.userEdit.email,
					picture: this.userEdit.picture || '',
					profile: this.userEdit.profile ? JSON.stringify( this.userEdit.profile ) : '',
				});
				
				if ( this.userEdit.email != this.signedInUser.attributes.email) {
					this.verifyEmail = true;
					this.$notify({ type: 'success', text: 'Brukerprofilen ble lagret, men du må verifisere din endrede epostadresse.' });
				}
				else {
					this.$notify({ type: 'success', text: 'Brukerprofilen ble lagret' });
				}
				
				await this.createCopy();
			} 
			catch (e) {
				console.error(e);
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke lagre brukerprofil'
				});
			}
			finally {
				this.loading = null;
			}
		},
		
		async onVerifyCurrentUserAttributeSubmit(){
			try {
				this.loading = 'verifying';
				const response = await Auth.verifyCurrentUserAttributeSubmit('email', this.code);
				console.log( response );
				this.verifyEmail = false;
				
				this.showNeedToSignOut = true;
			} 
			catch (e) {
				console.error(e);
				this.$notify({ 
					type: 'error', 
					text: e.message || 'Kunne ikke verifisere koden'
				});
			}
			finally {
				this.loading = null;
			}
		},
	},
	
	async mounted(){
		await this.createCopy();
	},
}
</script>

